import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Context from "context/"
import ContentWrapper from "styles/contentWrapper"
import SplashScreen from "components/splashScreen"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  padding: 3.5rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 45rem;
  }
  .bg-image {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
    margin-top: 3rem;
    min-height: 14rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-top: 0;
      min-height: 60vh;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin-top: 0;
      min-height: 30rem;
    }
  }
`

const Hero = ({ content }) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://s3.amazonaws.com/img.riminder.net/animations/cutomers-animation.js";
    script.async = true;
    document.body.appendChild(script);
  }, [])

  const { frontmatter } = content[0].node
  const { isIntroDone, darkMode } = useContext(Context).state
  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, darkMode, gControls])
  
  const teamPhoto = getImage(frontmatter.teamPhoto)
  const mdxbgImage = getImage(frontmatter.mdxbgImage)

  return (
    <StyledSection id="hero">
      {!isIntroDone && <SplashScreen />}
      <GatsbyImage className="bg-image" image={mdxbgImage}  alt="background image" />
      <StyledContentWrapper>
        <div className="intro">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={gControls}
            data-testid="animated-heading"
          >
            <div id="lottie"></div>
          </motion.div>
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
